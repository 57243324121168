import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import {
  WordpressJson,
  WordpressJsonExcerpt,
  WordpressJsonTitle,
  MicrocmsBlogs,
  MicrocmsBlogsCategories,
  ConvertHtml,
  GatsbyImageSharpFixedFragment,
} from '../../types/graphql-types'
import {
  Typography,
  Grid,
  makeStyles,
  Link as MuiLink,
  Chip,
} from '@material-ui/core'
import { Section } from './Typography'
import GatsbyImage from 'gatsby-image'
import { moment } from '../utils/moment'

type OldPost = {
  node: Pick<
    WordpressJson,
    'id' | 'date' | 'categories' | 'jetpack_featured_media_url'
  > & {
    excerpt?: Pick<WordpressJsonExcerpt, 'rendered'>
    title?: Pick<WordpressJsonTitle, 'rendered'>
  }
}

type Post = {
  node: Pick<MicrocmsBlogs, 'date' | 'blogsId' | 'title'> & {
    categories?: Array<Pick<MicrocmsBlogsCategories, 'name' | 'id'> | undefined>
    childConvertHtml?: Pick<ConvertHtml, 'plainText'>
    localThumbnail?: {
      childImageSharp?: { fixed?: GatsbyImageSharpFixedFragment }
    }
  }
}

const useStyles = makeStyles(theme => ({
  post: { marginBottom: theme.spacing(8) },
  image: { objectFit: 'cover', width: 150, height: 150 },
  chip: { margin: theme.spacing(0.5) },
}))

export const isOld = (edge: OldPost | Post): edge is OldPost =>
  edge.node.hasOwnProperty('jetpack_featured_media_url')

export const categoryMapping: Record<number, string> = {
  1: 'wsvev4l_5', // Others
  4: 'cqqyyq_ny', // Live Information
  5: 'dgg3mv4zn', // Live Report
  6: 'ztpasgrtx', // Live Movie
}

const Thumbnail: React.FC<{
  edge: OldPost | Post
  alt?: string
}> = ({ edge, alt }) => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query DefaultThumbnail {
      file(relativePath: { eq: "logo_twitter.png" }) {
        childImageSharp {
          fixed(width: 150, height: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  if (isOld(edge) && edge.node.jetpack_featured_media_url) {
    return (
      <img
        className={classes.image}
        src={edge.node.jetpack_featured_media_url}
        alt={alt}
      />
    )
  } else if (!isOld(edge) && edge.node.localThumbnail) {
    return (
      <GatsbyImage
        className={classes.image}
        fixed={edge.node.localThumbnail.childImageSharp?.fixed}
        alt={alt}
      />
    )
  } else {
    return (
      <GatsbyImage
        className={classes.image}
        fixed={data.file.childImageSharp.fixed}
        alt={alt}
      />
    )
  }
}

type Props = {
  posts: (Post | OldPost)[]
  categories: {
    node: {
      categoriesId?: string
      name?: string
    }
  }[]
}

const CategoryChip: React.FC<{ label?: string; to: string }> = ({
  label,
  to,
}) => {
  const { chip } = useStyles()
  return (
    <Chip
      className={chip}
      size="small"
      clickable
      component={Link}
      label={label}
      to={to}
    />
  )
}

const AllBlogPost: React.FC<Props> = ({ posts, categories }) => {
  const classes = useStyles()
  return (
    <div>
      {posts.map(edge => (
        <Grid container item className={classes.post} spacing={1}>
          <Grid item xs={12}>
            <Section>
              <MuiLink
                color="inherit"
                component={Link}
                to={`/blog/${
                  isOld(edge) ? edge.node.id : edge.node.blogsId
                }.html`}
              >
                {isOld(edge) ? edge.node.title?.rendered : edge.node.title}
              </MuiLink>
            </Section>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Link
              to={`/blog/${
                isOld(edge) ? edge.node.id : edge.node.blogsId
              }.html`}
            >
              <Thumbnail
                edge={edge}
                alt={isOld(edge) ? edge.node.title?.rendered : edge.node.title}
              />
            </Link>
          </Grid>
          <Grid item xs={12} sm>
            <Typography
              variant="subtitle1"
              component="div"
              color="textSecondary"
            >
              {moment(edge.node.date).format('YYYY/MM/DD HH:mm')}
            </Typography>
            {isOld(edge)
              ? edge.node.categories?.map(
                  n =>
                    n != null && (
                      <CategoryChip
                        label={
                          categories.find(
                            c => c.node?.categoriesId === categoryMapping[n]
                          )?.node?.name
                        }
                        to={`/blog/categories/${categoryMapping[n]}`}
                      />
                    )
                )
              : edge.node.categories?.map(c => (
                  <CategoryChip
                    label={c?.name}
                    to={`/blog/categories/${c?.id}`}
                  />
                ))}
            {isOld(edge) ? (
              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: edge.node.excerpt!.rendered!,
                }}
              />
            ) : (
              <Typography variant="body1">
                {(edge.node.childConvertHtml?.plainText?.length ?? 0) > 110
                  ? `${edge.node.childConvertHtml?.plainText?.substr(0, 110)} …`
                  : edge.node.childConvertHtml?.plainText}
              </Typography>
            )}
          </Grid>
        </Grid>
      ))}
    </div>
  )
}

export default AllBlogPost
